import { SVGProps } from "react"

const BgBttom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 834 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="834"
      height="71"
    >
      <path fill="#D9D9D9" d="M0 0h834v71H0z" />
    </mask>
    <g>
      <path
        d="M0-375.838c93.873-38.669 264.782 14.425 416.035 10.875 128.425-3.014 242.044-72.02 417.965-49.544V15.841C726.614-31.045 509.909 55.235 346.776 66.594 183.644 77.953 97.01 15.841 0 46.051v-421.889Z"
        fill="#E6F1FB"
      />
    </g>
  </svg>
)

export default BgBttom
