import { SVGProps } from "react"

const BgTop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 834 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={834}
      height={85}
    >
      <path fill="#D9D9D9" d="M0 0h834v85H0z" />
    </mask>
    <g>
      <path
        d="M0 43.162C93.873 4.493 264.782 57.587 416.035 54.037 544.46 51.023 658.079-17.983 834 4.493v430.348c-107.386-46.886-324.091 39.394-487.224 50.753C183.644 496.953 97.01 434.841 0 465.051V43.161Z"
        fill="#E6F1FB"
      />
    </g>
  </svg>
)

export default BgTop
