import { Flex } from "@chakra-ui/react"
import Head from "next/head"
import { useTranslation } from "next-i18next"

import SnowdayHelp from "./components/SnowdayHelp"
import SnowdayIntro from "./components/SnowdayIntro"
import SnowdayMission from "./components/SnowdayMission"
import TopSearchView from "@/search/components/topSearch/TopSearchView"
import { TopSearchDataType } from "@/search/types"
import { AskSnowdayBlock } from "@/ui/askSnowday/AskSnowdayBlock"
import Hero from "@/ui/common/Hero"

interface HomeScreenProps {
  topSearches: TopSearchDataType[]
}

const HomeScreen = ({ topSearches = [] }: HomeScreenProps) => {
  const { t } = useTranslation("titles")

  return (
    <>
      <Head>
        <title>{`Snowday | ${t("page-titles.home")}`}</title>
      </Head>

      <Flex
        as="main"
        minHeight={{
          base: "calc(100vh - 48px)",
          md: "calc(100vh - 56px)",
          lg: "calc(100vh - 64px)",
        }}
        flexDirection="column"
      >
        <Hero topSearches={topSearches} />
        <TopSearchView data={topSearches} />
        <SnowdayIntro />
        <SnowdayMission />
        <AskSnowdayBlock light />
        <SnowdayHelp />
      </Flex>
    </>
  )
}

export default HomeScreen
