import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react"

import SearchScreen from "../screen"
import { TopSearchDataType } from "../types"
import { Header } from "@/ui/common/Header"
import Footer from "@/ui/footer/Footer"

type SearchPageModalProps = {
  onClose: () => void
  isOpen: boolean
  topSearchData: TopSearchDataType[]
}

export const SearchPageModal = ({
  onClose,
  isOpen,
  topSearchData,
}: SearchPageModalProps) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size="full"
      scrollBehavior="outside"
      isCentered={false}
    >
      <ModalOverlay />
      <ModalContent m={0} top="0">
        <Header title={"learning-opportunities"} onClick={onClose} />
        <SearchScreen topSearches={topSearchData} onClick={onClose} />
        <Footer />
      </ModalContent>
    </Modal>
  )
}
