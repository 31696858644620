import {
  Box,
  Flex,
  Heading,
  LinkBox,
  LinkOverlay,
  useDisclosure,
} from "@chakra-ui/react"

import SearchInput from "../../search/components/SearchInput"
import { SearchPageModal } from "../../search/components/SearchPageModal"
import { TopSearchDataType } from "../../search/types"

interface HeroProps {
  topSearches: TopSearchDataType[]
}

export default function Hero({ topSearches }: HeroProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box
      height={{ base: "250px", md: "468px", lg: "580px", xl: "640px" }}
      bgColor="var(--chakra-colors-snow-blue-hero)"
      maxHeight="640px"
      as="section"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Heading
        as="h1"
        variant="heroTitle"
        fontWeight="bold"
        fontSize={{ base: "24px", lg: "48px" }}
      >
        Top Extracurriculars
      </Heading>
      <Flex mt={2}>
        <Heading
          variant="heroSubtitle"
          fontWeight="hairline"
          fontSize={{ base: "16px", lg: "24px" }}
        >
          Curated by College Consultants
        </Heading>
      </Flex>
      <SearchPageModal
        onClose={onClose}
        isOpen={isOpen}
        topSearchData={topSearches}
      />
      <LinkBox width={{ base: "300px", lg: "600px" }} mt={{ base: 4, lg: 8 }}>
        <LinkOverlay cursor="pointer" onClick={onOpen}>
          <SearchInput goBack={false} />
        </LinkOverlay>
      </LinkBox>
    </Box>
  )
}
