import {
  Box,
  Container,
  Flex,
  Heading,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react"

import SnowdayIcons from "@/icons/SnowdayIcons"
import { breakpoints, useWindowSize } from "@/ui/hooks"
import BgBttom from "@/ui/waves/BgBottom"
import BgTop from "@/ui/waves/BgTop"

export default function SnowdayIntro() {
  const { width } = useWindowSize()
  const isSmallScreen = width && width < breakpoints.lg

  return (
    <>
      <BgTop width="100%" />
      <Box
        as="section"
        pt={{ base: 5, lg: 10 }}
        pb={{ base: 10, lg: 20 }}
        bg="snow.blue-light"
        px={{ base: 6, md: 10 }}
      >
        <Container px={0} maxWidth={{ lg: "890px" }}>
          <Heading
            mx="auto"
            variant="h2"
            color="snow.blue-medium"
            textAlign="center"
            maxW={{ md: "330px", lg: "470px" }}
            mb={12}
            fontWeight="bold"
          >
            The most comprehensive database of learning opportunities for high
            schoolers.
          </Heading>
          <Flex flexDirection={isSmallScreen ? "column" : "row"} gap={10}>
            <Flex
              flexDirection="row"
              gap={4}
              alignItems="center" // Adjust alignment as needed
              justifyContent="center" // Center the content within the Flex
              width="100%" // Ensure Flex takes the full width
            >
              <SnowdayIcons name="Research" boxSize={24} />
              <Box>
                <Heading variant="h3" mb={2} fontWeight="bold">
                  Search 1,900+ Opportunities
                </Heading>
                <UnorderedList>
                  <ListItem>New: 700 school year programs</ListItem>
                  <ListItem>Soon: 700+ competitions</ListItem>
                  <ListItem>1,200+ top summer programs</ListItem>
                </UnorderedList>
              </Box>
            </Flex>
            <Flex
              flexDirection="row"
              gap={4}
              alignItems="center" // Adjust alignment as needed
              justifyContent="center" // Center the content within the Flex
              width="100%" // Ensure Flex takes the full width
            >
              <SnowdayIcons name="FlowerPot" boxSize={24} />
              <Box>
                <Heading variant="h3" mb={2} fontWeight="bold">
                  Save Time and Don't Miss Out
                </Heading>
                <UnorderedList>
                  <ListItem>Search powered by AI</ListItem>
                  <ListItem>Filter by location, date, cost, and more</ListItem>
                  <ListItem>Watch program videos</ListItem>
                </UnorderedList>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Box>
      <BgBttom width="100%" />
    </>
  )
}
