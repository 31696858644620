import { Box, Container, Flex, Heading, Link, Text } from "@chakra-ui/react"
import { useCallback, useRef } from "react"

import { FinancialAccessibility } from "@/generated/graphql"
import {
  FinancialAccessibilityBadge,
  FinancialAccessibilityHandle,
} from "@/ui/badges/FinancialAccessibilityBadge"
import BgBottom2 from "@/ui/waves/BgBottom2"
import BgTop2 from "@/ui/waves/BgTop2"

export default function SnowdayMission() {
  const financialRef = useRef<FinancialAccessibilityHandle>(null)

  const toggleModal = useCallback(() => {
    if (!financialRef.current) {
      return
    }
    financialRef.current.toggleModal()
  }, [])

  return (
    <>
      <BgTop2 width="100%" />
      <Box as="section" pb={5} pt={{ base: 5 }} bg="snow.blue-light">
        <Container px={{ base: 0, md: 8, lg: 0 }} maxWidth={{ lg: "890px" }}>
          <Heading
            mx="auto"
            variant="h2"
            color="snow.blue-medium"
            textAlign="center"
            maxW={{ md: "413px" }}
            mb={{ base: 6, lg: 12 }}
          >
            Our mission is to make out-of-school education equally accessible to
            all.
          </Heading>
          <Flex
            maxW="250px"
            alignItems="center"
            flexDirection="column"
            mx="auto"
            textAlign="center"
          >
            <Heading
              mx="auto"
              variant="h3"
              color="snow.blue-medium"
              textAlign="center"
              maxW={{ base: "220px", lg: "413px" }}
              mb={5}
            >
              Financial Accessibility
            </Heading>
            <FinancialAccessibilityBadge
              ref={financialRef}
              accessibility={FinancialAccessibility.APlus}
              includeLabelIcon
              size={120}
            />
            <Text mt={4} mb={4}>
              Find high quality opportunities that fit your budget.
            </Text>
            <Link
              color="snow.blue-light-medium"
              fontWeight={700}
              onClick={toggleModal}
            >
              Learn more
            </Link>
          </Flex>
        </Container>
      </Box>
      <BgBottom2 width="100%" />
    </>
  )
}
